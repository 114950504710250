import { createContext, useContext, useEffect, useState } from 'react';
import { Props } from '../@types';

export type GeolocationContextType = {
    coords: GeolocationCoordinates | undefined;
    isGeolocationEnabled: boolean;
    isGeolocationAvailable: boolean;
};

const DefaultGeolocationContext: GeolocationContextType = {
    coords: undefined,
    isGeolocationEnabled: false,
    isGeolocationAvailable: false,
};

const GeolocationContext = createContext<GeolocationContextType>(DefaultGeolocationContext);

const useProvideGeolocation = () => {
    const [isGeolocationEnabled, setIsGeolocationEnabled] = useState<boolean>(true);
    const [isGeolocationAvailable, setIsGeolocationAvailable] = useState<boolean>(true);
    const [coords, setCoords] = useState<GeolocationCoordinates | undefined>(undefined);

    useEffect(() => {
        if (navigator.geolocation) {
            setIsGeolocationAvailable(true);

            const watch = navigator.geolocation.watchPosition(
                position => {
                    setIsGeolocationEnabled(true);
                    setCoords(position.coords);
                },
                err => {
                    setIsGeolocationEnabled(false);
                    console.error(err);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 50000,
                }
            );

            return () => {
                console.log('clearWatch');
                navigator.geolocation.clearWatch(watch);
            };
        }
        setIsGeolocationAvailable(false);
        setIsGeolocationEnabled(false);
        setCoords(undefined);
    }, []);

    return {
        coords,
        isGeolocationEnabled,
        isGeolocationAvailable,
    };
};

const useGeolocation = () => {
    return useContext(GeolocationContext);
};

const ProvideGeolocation = ({ children }: Props) => {
    const geolocation = useProvideGeolocation();

    return <GeolocationContext.Provider value={geolocation}>{children}</GeolocationContext.Provider>;
};

export { useGeolocation, ProvideGeolocation };
