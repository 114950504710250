import { ReactNode } from 'react';
import { StopDetails } from './scheduler';

export type Props = {
    children: JSX.Element;
};

export type LayoutProps = {
    children: ReactNode;
    title?: string;
    licenseNumber?: string;
    lineName?: string;
};

export type Message = {
    content: string;
    senderName: string;
    senderId: string;
    createdAt: number;
};

export type MapProps = {
    lineName: string;
    lineStops: StopDetails[];
    setLineStops: (stops: StopDetails[]) => void;
    onNavigateBack: () => void;
};

export interface MenuItemType {
    value: string;
    label: string;
}

export const DefaultMenuItem: MenuItemType = {
    value: ' ',
    label: 'Kérem válasszon!',
};
